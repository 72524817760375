<template>
  <div class="px-4 mt-4">
    <h2 class="text-xl font-bold">
      {{ $t("Danh sách phiếu thu") }}
    </h2>
    <div class="flex flex-col md:flex-row gap-2">
      <el-input
        v-model="searchValue"
        placeholder="Tìm kiếm theo tên,..."
        @input="handleDebounce"
        clearable
      >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <div style="max-width: 400px; width: 100%">
        <el-date-picker
          class="cs-date-picker"
          v-model="filter.created_at"
          type="date"
          placeholder="Chọn ngày tạo phiếu"
        >
        </el-date-picker>
      </div>
      <div style="max-width: 400px; width: 100%">
        <el-select
          class="w-full"
          v-model="filter.status"
          placeholder="Trạng thái thanh toán"
          clearable
        >
          <el-option
            v-for="item in PAYMENT_TRANSACTION_STATUS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-table
      class="mt-4"
      :data="receipts"
      @row-click="handleViewDetail"
      row-class-name="cursor-pointer"
    >
      <el-table-column
        prop="number_code"
        label="Mã phiếu thu"
        min-width="250px"
      >
      </el-table-column>
      <el-table-column prop="name" label="Tên phiếu thu" min-width="250px">
      </el-table-column>
      <el-table-column
        :formatter="
          (row) =>
            row.created_at ? appUtils.formatTimeDate(row.created_at) : ''
        "
        label="Ngày lập"
        min-width="150px"
      >
      </el-table-column>
      <el-table-column
        prop="created_by.name"
        label="Người lập"
        min-width="150px"
      >
      </el-table-column>
      <el-table-column label="Trạng thái" min-width="150px">
        <template slot-scope="scope">
          <div :set="(statusInfo = getPaymentStatus(scope.row))">
            <el-tag
              class="w-full"
              size="small"
              :type="statusInfo.type"
              effect="dark"
            >
              {{ statusInfo.label }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="180px">
        <template slot-scope="scope">
          <div class="flex gap-2 items-center justify-end">
            <el-button
              v-if="
                scope.row.service_payment_transaction &&
                scope.row.service_payment_transaction.status === 1
              "
              @click.stop="handleOpenModalPayment(scope.row)"
              type="primary"
              size="small"
              >Thanh toán</el-button
            >
            <svg
              width="36"
              height="30"
              viewBox="0 0 46 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="1.27686"
                width="45"
                height="39"
                rx="3.5"
                stroke="#20409B"
              />
              <mask
                id="mask0_35326_657"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="9"
                y="5"
                width="28"
                height="31"
              >
                <rect
                  x="10.3572"
                  y="6.27686"
                  width="25.2857"
                  height="28.5167"
                  fill="#D9D9D9"
                  stroke="#20409B"
                />
              </mask>
              <g mask="url(#mask0_35326_657)">
                <path
                  d="M23.0001 25.4661C24.3729 25.4661 25.5399 24.9264 26.5005 23.8478C27.461 22.7692 27.9411 21.4594 27.9411 19.9193C27.9411 18.3791 27.461 17.0694 26.5005 15.9908C25.5399 14.9121 24.3729 14.3724 23.0001 14.3724C21.6273 14.3724 20.4602 14.9121 19.4997 15.9908C18.5392 17.0694 18.059 18.3791 18.059 19.9193C18.059 21.4594 18.5392 22.7692 19.4997 23.8478C20.4602 24.9264 21.6273 25.4661 23.0001 25.4661ZM23.0001 23.2274C22.1824 23.2274 21.4875 22.9062 20.9148 22.2631C20.3419 21.6198 20.0554 20.8388 20.0554 19.9193C20.0554 18.9997 20.3419 18.2187 20.9148 17.5755C21.4875 16.9323 22.1824 16.6111 23.0001 16.6111C23.8177 16.6111 24.5126 16.9323 25.0854 17.5755C25.6582 18.2187 25.9447 18.9997 25.9447 19.9193C25.9447 20.8388 25.6582 21.6198 25.0854 22.2631C24.5126 22.9062 23.8177 23.2274 23.0001 23.2274ZM23.0001 29.1307C20.3378 29.1307 17.9127 28.2964 15.7243 26.6274C13.537 24.9593 11.9508 22.7234 10.9657 19.9193C11.9508 17.1151 13.537 14.8792 15.7243 13.2111C17.9127 11.5421 20.3378 10.7078 23.0001 10.7078C25.6623 10.7078 28.0874 11.5421 30.2758 13.2111C32.4631 14.8792 34.0494 17.1151 35.0344 19.9193C34.0494 22.7234 32.4631 24.9593 30.2758 26.6274C28.0874 28.2964 25.6623 29.1307 23.0001 29.1307Z"
                  fill="#20409B"
                  stroke="#20409B"
                  stroke-width="0.025"
                />
              </g>
            </svg>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <AppPagination
      :paginationProps="pagination"
      @onChangePagination="handleGetListReceipt"
    />
    <ModalPaymentReceipt
      ref="ModalPaymentReceipt"
      @onRefresh="handleGetListReceipt"
    />
  </div>
</template>

<script>
import { AppPagination } from '@/components/Common'
import { PAYMENT_TRANSACTION_STATUS } from '@/utils/constants'
import ModalPaymentReceipt from './ModalPaymentReceipt.vue'
import appUtils from '@/utils/appUtils'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
  name: 'PaymentReceipts',
  components: { AppPagination, ModalPaymentReceipt },

  data () {
    return {
      searchValue: '',
      filter: {
        created_at: null,
        status: null
      },
      PAYMENT_TRANSACTION_STATUS,
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      },
      receipts: [],
      appUtils
    }
  },
  created () {
    this.handleGetListReceipt()
  },
  watch: {
    filter: {
      deep: true,
      handler () {
        this.handleGetListReceipt()
      }
    }
  },
  methods: {
    getPaymentStatus (row) {
      switch (row?.service_payment_transaction?.status) {
        case PAYMENT_TRANSACTION_STATUS[1].value:
          return {
            label: PAYMENT_TRANSACTION_STATUS[1].label,
            type: 'danger'
          }
        case PAYMENT_TRANSACTION_STATUS[2].value:
          return {
            label: PAYMENT_TRANSACTION_STATUS[2].label,
            type: 'success'
          }
        default:
          return {
            label: '',
            type: ''
          }
      }
    },
    async handleGetListReceipt (data) {
      try {
        const params = {
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          keyword: this.searchValue,
          my_order: 2,
          date: this.filter.created_at
            ? moment(this.filter.created_at).unix()
            : undefined,
          payment_status: this.filter.status || undefined
        }

        const response = await this.$rf
          .getRequest('AuthRequest')
          .getListPaymentReceipt(params)

        const responseData = response.data || {}
        this.receipts = responseData?.data || []
        this.pagination = {
          currentPage: responseData.page?.page_num || 1,
          pageSize: Number(responseData?.page.page_size) || 15,
          totalItems: responseData.page?.total || 0
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleOpenModalPayment (row) {
      this.$refs.ModalPaymentReceipt.handleOpen(row)
    },
    handleViewDetail (row) {
      this.$router.push({
        name: 'ReceiptDetail',
        params: {
          id: row.id
        }
      })
    },
    handleDebounce: debounce(function () {
      this.handleGetListReceipt()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.cs-date-picker {
  width: 100% !important;
}

.cs-table {
  width: 1400px !important;
}
</style>
